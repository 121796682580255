@use '@angular/material' as mat;
// @import '@angular/material/theming';
@import "app/variables";
// @import '~quill/dist/quill.snow.css';
// @import '~quill/dist/quill.bubble.css';


// Plus imports for other components in your app.

@include mat.all-component-typographies();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$MR-Vital-CRM-primary: mat.define-palette($primary-palette);
$MR-Vital-CRM-accent: mat.define-palette($secondary-palette);

// The warn palette is optional (defaults to red).
$MR-Vital-CRM-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$custom-typography: mat.define-typography-config(
  $font-family:  '"Helvetica Neue", Helvetica, Arial, sans-serif',
);

$mr-theme: mat.define-light-theme((
  color: (
    primary: $MR-Vital-CRM-primary,
    accent: $MR-Vital-CRM-accent,
    warn: $MR-Vital-CRM-warn,
  ),
  typography: $custom-typography,
  density: 0
));

/* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated*/
// @include mat.all-legacy-component-themes($mr-theme);

@include mat.all-component-themes($mr-theme);

@import 'app/mat.excavation';

html, body { height: 100%; }
body { margin: 0; font-family: $font-family-base; background-color: $color-bgmain; }

input.nonMatInput{
  border-radius: 3px;
  border:1px solid map-get($mr-theme, primary, 100);
}
input[type='text'].nonMatInput{
  height: 18px;
  font-size: 16px;
  padding: ($component-gap - 1px) $component-gap;
}

// mat-datepicker-toggle{
//   button.mat-mdc-icon-button{
//     position: absolute;
//     top: 7px;
//     right: 0px;
//   }
// }
.dateSearch{
  display: flex;
  
  input.nonMatInput{
    margin-right: -($widget-gap+$widget-gap);
    padding-right: $widget-gap+$widget-gap;
    padding-left: $component-gap;
    font-size: 16px;
    background-color: map-get($mr-theme, primary, 00);
  }
  
  mat-datepicker-toggle{
    button.mat-mdc-icon-button{
      position: relative;
      top: unset;
      right: unset;
    }
  }
}
.mat-mdc-menu-panel{
  .mat-mdc-menu-content{
    fa-icon{
      font-size: 18px;
      margin-right: $component-gap;
    }
    
    & > div{
      & > mat-label{
        padding-left: $component-gap;
        padding-right: $component-gap;
        font-weight: 500;
      }
    }
  }
  div.mat-mdc-menu-content{
    display:flex;
    flex-direction: column;
    gap:$component-gap;
    
    button.twoLine{
      .mat-mdc-menu-item-text{
        align-items: center;
        display: flex;
        line-height: 1.125rem;
      }
    }
  }
}

fa-icon.primary-icon{
  color: map-get($mr-theme, accent, A200);
      
  svg path{
      stroke: map-get($mr-theme, accent, 900);
      stroke-width: 26px;
  }
}

mat-tooltip-component {
  --mdc-plain-tooltip-container-color: #{map-get($mr-theme, primary, 600)};
}
.multilineTooltip {
  white-space: pre-line;
  .mdc-tooltip__surface{
    text-align: left;
  }
}

.mat-vertical-stepper-header{
  padding: 20px 24px;
  margin: 0px 0px 4px 0px;
}

//Imitate mat input bottom padding for special elements
span.form-bottom-pad-group, div.form-bottom-pad-group{
  padding-bottom: 1.25em;
}

.stepper-breadcrumbs{
  display: flex;
  gap: $small-gap;
  padding: 2px $widget-gap;
  .crumb{
    margin:auto;
    font-size: 12px;
    height: 32px;
    text-decoration: underline;
  }
}
.flex{
  display: flex;
  &.row{
    flex-direction: row;
    gap: $component-gap;
  }
  &.col{
    flex-direction: column;
    gap: $component-gap;
  }
  &.small-gap{
    gap: $small-gap;
  }
  &.big-gap{
    gap: $widget-gap;
  }
  
  &.wrap{
    flex-wrap: wrap;
  }
}
.flex-row{
    display: inline-flex;
    flex-direction: row;
    gap: $component-gap;
    
    &.search-row, &.flex-wrap{
      flex-wrap: wrap;
    }
}

.flex-row, .search-row, .flex, .titleRow{
    .center{
        text-align: center;
    }
    .center-align{
        align-self: center;
    }
        
    .ml-auto, .mx-auto{
        margin-left:auto;
    }
    .mr-auto, .mx-auto{
        margin-right:auto;
    }
    &.w-100, .w-100{
        width: 100%;
    }
}
.flex-row, .search-row, .flex, .form-row{
  .grow{
    flex-grow: 1;
  }
  .grow_0{
    flex-grow: 0;
  }
}
mat-form-field.ng-invalid, mat-form-field.required-invalid, .required-invalid{
  mat-label, label, span{
    &.required-label{
      color:var(--requiredColor);
      &::after{
        content: "*";
      }
    }
  }
  input.required-label{
    &::placeholder{
      color:var(--requiredColor);
    }
  }
}
mat-form-field{
  --mat-form-bg: #{map-get($mr-theme, primary, 00)};
  
  &:hover{
    .mat-mdc-form-field-focus-overlay{
      opacity: 0;
    }
    font-weight: 400;
  }
  &.mat-form-field-appearance-fill{
    &.mat-focused{
      background-color: var(--mat-form-bg);
      .mat-mdc-form-field-focus-overlay{
        opacity: 0;
      }
      .mat-mdc-form-field-infix{
        label.mdc-floating-label.mdc-floating-label--float-above {
          font-weight: 500;
          color: #000b;
        }
      }
    }
    
    .mdc-text-field{
      padding: 0px 0px;
    }
    
    .mdc-text-field--filled{
      background-color: var(--mat-form-bg);
    }
    
    .mat-mdc-form-field-infix{
      label.mdc-floating-label.mdc-floating-label--float-above {
        transform: translateY(-120%) scale(0.75);
      }
      mat-label{
        line-height: 1.25em;
      }
    }
  }
}

form.flex-form {
  display:flex;
  gap:$component-gap;
  flex-direction: column;
        
  .expanded-card & {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  
  div.form-row{
    display:inline-flex;
    flex-wrap: wrap;
    gap:$component-gap;
    .fill-row{
      width: 100%;
      mat-form-field{
        width: 100%;
      }
      
      mat-slider{
        width:100%;
      }
        
      &.flex-items{
          display:flex;
          flex-direction: row;
          gap:$component-gap;
          flex-wrap: wrap;
          
          .grow{
            flex-grow: 1;
          }
      }
    }
  }
}

@mixin mobileForm{
  @include xs{
      .fill-row, .form-row .fill-row{
          grid-template-columns: 1fr;
          grid-template-areas: unset;
              
          & > *, & > span, & > div{//Bump css priority with specific element types
              grid-area:unset;
          }
      }
  }
}
      
.linkItem{
  text-decoration: underline;
  cursor: pointer;
}

span.deemphasize, fa-icon.deemphasize{
  // font-weight: 400;
  opacity: .65;
}

button{
  &.light-button{
    font-weight: 400;
    opacity: 0.7;
  }
  &.card-title-button{
    &, &.mat-mdc-button, &.mat-mdc-button-base, &.mat-mdc-button.mat-mdc-button-base{
      height: 24px;
      line-height: 14px;
      vertical-align: text-bottom;
    }
  }
  
  fa-icon{
    vertical-align: bottom;
  }
  
  &.color-icon-button{
      fa-icon{
          color:map-get($mr-theme, accent, 700);
      }
  }
}
input.mat-mdc-chip-input{
  margin-top: $small-gap;
  width: 100%;
}

mat-chip{
  .add-text-chip{
    display:flex;
    gap:$component-gap;
    
    fa-icon{
      font-size: 20px;
      margin-left: -4px;
    }
  }
  
  &.person-chip{
    @include mat.elevation(2);
    
    &.mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary{
      --mdc-chip-elevated-container-color: #{map-get($mr-theme, accent, 100)};
      --mdc-chip-label-text-color: #{map-get-contrast($mr-theme, accent, 100)};
    }
    &.accent.mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary{
      --mdc-chip-elevated-container-color: #{map-get($mr-theme, primary, A000)};
      --mdc-chip-label-text-color: #{map-get-contrast($mr-theme, primary, A000)};
    }
    
    &.xsmall{
      --mdc-chip-container-height: 24px;
    }
    
    &.mat-mdc-chip-highlighted{
      @include mat.elevation(6);
    }
    
    padding-right: 2px;
    padding-left: 2px;
                    
    &.extraRight{
        padding-right: 6px;
    }
    
    fa-icon.chipAction{
      font-size: 16px;
      opacity: 0.3;
      margin-left: $small-gap;
      cursor: pointer;
      
      &:hover{
        opacity: 0.45;
      }
    }
                    
    *[matchipaction]{
        padding: 0px;
                        
        .mdc-evolution-chip__text-label, .mat-mdc-chip-action-label{
            display: flex;
            align-items: center;
            gap:4px;
        }
    }
  }
}

mat-chip-grid {
  --mdc-typography-body2-font-weight: 500;
}

mat-button-toggle-group{
  mat-button-toggle{
    &.mat-button-toggle-checked{
      background-color: rgba(map-get($mr-theme, accent, 500), .3);
    }
  }
}

mat-divider{
  &.padded-divider{
    margin: $component-gap 0;
  }
}

mat-dialog-container{
  
  padding-top:$component-gap;
  
  .mat-mdc-dialog-surface{
    padding-top:$component-gap;
  }
  
  .mat-mdc-dialog-content{
      display:flex;
      flex-direction: column;
  }
  
  mat-dialog-content {
    @include customVerticalScrollStyle();
  }
}

mat-snack-bar-container{
  background-color: map-get($mr-theme, primary, 600);
  outline:2px solid map-get($mr-theme, primary, 400);
  border-radius: 3px;
  margin: 0px 0px 24px 0px;
  
  simple-snack-bar{
    .mat-mdc-snack-bar-label{
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      color: map-get-contrast($mr-theme, primary, 600);
    }
    // --mat-snack-bar-button-color: #{map-get($mr-theme, accent, 400)};
    [matsnackbaractions] {    
      button.mat-mdc-button {
        background-color: map-get($mr-theme, primary, 700);
        // .mat-button-wrapper{
        //   color: map-get($mr-theme, accent, 400);
        // }
      }
    }
  }
  
  &.light-snackbar{
    color: map-get-contrast($mr-theme, primary, 50);
    outline:2px solid map-get($mr-theme, primary, 100);
    
    .mdc-snackbar__surface{
      background-color: map-get($mr-theme, primary, 50);
      color: map-get-contrast($mr-theme, primary, 50);
      .mat-mdc-snack-bar-label{
        color: map-get-contrast($mr-theme, primary, 50);
        text-align: center;
      }
    }
    
    button.mat-mdc-button-base {
      background-color: $color-bgmain;
      color: map-get-contrast($mr-theme, primary, 50);
    }
  }

  fa-icon {
    margin-right: 4px;
  }
  
  // &.main-offset-snackbar{
  //  transform: translateX(calc(100% - 90px));
  // }
}

mat-label.form-group-label{
  display: block;
  // font-size: 11px;
  color: rgba(0, 0, 0, 0.54);
  // font-weight: 400;
  line-height: 1em;
  padding-bottom: 2px;
  transform: scale(0.75) translateX(-16.5%);
  width: 133.3333933333%;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.raisedFormSection{
  margin-left: -$component-gap;
  margin-right: -$component-gap;
  padding-left: $component-gap;
  padding-right: $component-gap;
  padding-top: $small-gap;
  margin-bottom: $small-gap;
  // margin-top: -5px;
}

.subText{
  font-size: 12px;
  font-weight: 400;
  color: map-get($mr-theme, primary, 400);
  
  &.sliderTop{
    font-size: 10px;
    opacity: .7;
    line-height: 1em;
    pointer-events: none;
  }
  
  &.no-overflow{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.redText{
  color: rgb(var(--redColor));
}

.commaSeperated:not(:last-child):after{
  content: ", ";
}

.mat-mdc-outlined-button.mat-primary{
  background-color: $color-bgmain;
}

button.mat-mdc-mini-fab.fa-icon-fab{
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .mat-button-wrapper{
    padding:0px;
    display:flex;
    fa-icon{
      font-size: 19px;
      line-height: 18px;
      padding: 0px;
      display: flex;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

mat-card{
  --mat-card-title-text-line-height: 28px;
  
  &.mat-mr{
    // outline: solid $small-gap map-get($mr-theme, accent, 500);
    border-radius: 4px;
    border: 3px solid rgba(map-get($mr-theme, accent, 900), .64);
    background-color: map-get($mr-theme, accent, 20);
    padding: $small-gap $component-gap $component-gap $component-gap;

    @include mat.elevation(6);
    
    &.mr-accent{
      border: 3px solid map-get($mr-theme, primary, A200);
      background-color: map-get($mr-theme, primary, A100);
    }
  }  
  
  &.grid-card, &.mat-mr{
    min-height: 80px;
    gap:$component-gap;
    
    &.no-gap{
      gap:0px;
    }
    
    mat-card-actions{
      gap: $component-gap;
      margin: 0px;
      padding:0px;
    }
    mat-card-content{
      display:flex;
      flex-direction: column;
      gap: $component-gap;
      margin: 0px;
      padding: 0px;
    }
  }
  mat-card-title{
    .widgetIcons{
      display: inline-flex;
      gap:$widget-gap;
      font-size: 16px;
      height: 20px;
      align-items: center;
      & > fa-icon{
        cursor: pointer;
      }
      
      .resize{
        @include md {
          display: none;
        }
      }
    }
    
    &.titleRow{
      display:grid;
      gap:$component-gap;
      grid-template-columns: auto 1fr auto;
        
      span{
        align-self: center;
        &.flex{
          display: flex;
          gap: $small-gap;
        }
      }

      &.buttonTitle{
        grid-template-columns: auto 1fr auto auto;
      }
      &.iconTitle{
        cursor: pointer;
        & > span.titleAndIcon{    
          display: flex;
          align-items: center;
          
          & > span{
            display: inline-flex;
            vertical-align: middle;
          }
          mat-icon{
            display: inline-flex;
            vertical-align: middle;
            min-width: 18px;
          }
        }
      }
    }
  }
  
  &.collapseWidget{
    gap:0px;
    
    mat-card-content{
      height: 0px;
      max-height: 0px;
      min-height: 0px;
      gap:0px;
      overflow: hidden;
      opacity: 0;
    }
      
    transition: 
              height .2s, 
              max-height .2s, 
              min-height .2s, 
              gap .2s, 
              opacity .2s;
  }
}

@mixin icon-name-group {
  display:grid;
  gap: 8px;
  grid-template-columns: 36px auto;
  align-items: center;
}
@mixin icon-name-group-with-checkbox{
  @include icon-name-group;
  grid-template-columns: 24px 36px auto;
  
  mat-checkbox {
    display: flex;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
  }
}

.user-icon-name-group {
  @include icon-name-group;
}

table{
  font-family: $font-family-base;
  --mdc-typography-subtitle2-line-height: 12px;
  --mdc-typography-subtitle2-font-size: 12px;
  // &.mat-mdc-table thead{
  //   background-color: $row-main;
  // }
  tr {
    th{
      line-height: 1.1em;
      &.mat-mdc-header-cell:first-of-type,  &.mat-mdc-cell:first-of-type,  &.mat-mdc-footer-cell:first-of-type {
        &.mat-column-user-icon {
          & > div {
            &.with-checkbox {
              @include icon-name-group-with-checkbox;
              grid-template-columns: 24px auto;
            }
          }
        }
      }
    }
    
    td{
      overflow: visible;
      position: relative;
      &.mat-mdc-header-cell:first-of-type,  &.mat-mdc-cell:first-of-type,  &.mat-mdc-footer-cell:first-of-type {
        &.mat-column-user-icon {
          & > div {
            @include icon-name-group;
            
            &.with-checkbox {
              @include icon-name-group-with-checkbox;
            }
          }
        }
      }
            
      .multiLine {
        overflow:auto;
      }
    }
    td,th{
      &.mat-mdc-header-cell:first-of-type,  &.mat-mdc-cell:first-of-type,  &.mat-mdc-footer-cell:first-of-type {
        &.mat-column-user-icon {
          padding: ($small-gap * 2) $small-gap;
          border-right: 0px;
        }
      }
      &.cdk-column-table-more, &.mat-column-table-more, 
      &.action-cell{
        button{
          margin-left: auto;
          display: flex;
        }
      }
            
      .multiLine {
        display:flex;
        flex-direction: column;
        gap:$small-gap;
        line-height: 1em;
      }

      &.mat-column-table-edit,
      &.mat-column-table-delete {
        font-size: 28px;
        width: 42px;
        text-align: center;
        fa-icon svg {
          cursor: pointer;
          color: map-get($mr-theme, primary, 500);
          &:hover, &:focus{
            // @include mat.elevation(8);
            filter: drop-shadow(0 3px 4px map-get($mr-theme, primary, 300));
          }
        }
      }
      &.mat-column-prospect-score{
        width: 36px;
      }
      &.mat-column-prospect-contact {
        width: 80px;
      }
      &.mat-column-first-last-contact {
        width: 90px;
      }
      
      input[type=text]{
        width: calc(100% - $widget-gap);
      }
    }
  }
  &.mat-mr{
    width: 100%;
    border-top: 1px solid map-get($mr-theme, primary, 200);
    border-left: 1px solid map-get($mr-theme, primary, 200);
    border-right: 1px solid map-get($mr-theme, primary, 200);
    thead{
      tr{
        height:unset;
        th{
          border-bottom: 1px solid map-get($mr-theme, primary, 200);
          // border-right: 1px solid map-get($mr-theme, primary, 200);
          padding: 8px;
          font-size: 12px;
          font-weight: 500;
          
          &.mat-mdc-header-cell{
            color: map-get($mr-theme, primary, 600);
          }
          
        }
      }
    }
    tbody{
      tr{
        td{
          border-bottom: 1px solid map-get($mr-theme, primary, 200);
          // border-right: 1px solid map-get($mr-theme, primary, 200);
          min-height: 52px;
          padding: 8px;
          font-size: 15px;
          
          mat-select.table-inline-select{
            padding: 8px;
            background: $color-bgmain;
            border: 1px solid map-get($mr-theme, primary, 100);
            border-radius: 3px;
            margin-left: -10px;
          }
          
          .dates{
            text-align: center;
          }
          
          .vertical-center{
            display: flex;
            align-items: center;
          }
        }
        background-color: $row-main;
        &:nth-of-type(odd){
          background-color: $row-off;
        }
        &.selected{
          background-color: $row-selected;
        }
      }
    }
  }
}

mat-tab-group{
  mat-tab-header{
    border-bottom: 1px solid rgba(0,0,0,.1);
  }
}

@mixin infiniteTableFillRemaining($other-element-heights){  
  .infinite-table{
    max-height: calc(var(--mainContentHeightCompatibility) - ($other-element-heights));
    max-height: calc(var(--mainContentHeight) - ($other-element-heights));
    @include sm{
      max-height: calc(var(--mainContentHeightCompatibility) - ($other-element-heights + $widget-gap));
      max-height: calc(var(--mainContentHeight) - ($other-element-heights + $widget-gap));
    }
    overflow-x: hidden;
    overflow-y: auto;
    background-color: map-get($mr-theme, primary, 00);
    
    @include mat.elevation(1); 

    border-top: 1px solid map-get($mr-theme, primary, 200);
    border-left: 1px solid map-get($mr-theme, primary, 200);
    border-right: 1px solid map-get($mr-theme, primary, 200);
    padding-bottom: 10px;
    
    table{
      border-top:0px;
      border-left:0px;
      border-right:0px;
    }
  }
}

@mixin infiniteTableWithSetHeight($set-height){
  .infinite-table {
    max-height: $set-height;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: map-get($mr-theme, primary, 00);
    
    @include mat.elevation(1); 

    border-top: 1px solid map-get($mr-theme, primary, 200);
    border-left: 1px solid map-get($mr-theme, primary, 200);
    border-right: 1px solid map-get($mr-theme, primary, 200);
    table{
      border-top:0px;
      border-left:0px;
      border-right:0px;
    }
  }
}

@mixin multilineClip($lines){
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $lines; /* number of lines to show */
          line-clamp: $lines; 
  -webkit-box-orient: vertical;    
  line-height: 1.1em;
  margin-bottom: -.05em;
  margin-top: -.05em;
}

//Mixin from: https://stackoverflow.com/a/54920508
//@include mat-table-columns((
//  colname1: (max-width: 6rem, color: gray),
//  colname2: (max-width: 9rem),
//  colname3: (max-width: 20rem)
//));

@mixin mat-table-columns($columns)
{
    .mat-column-
    {
        @each $colName, $props in $columns {
    
                
            &#{$colName} 
            {
                // flex: $width;

                @if map-has-key($props, 'width') 
                {
                    width: map-get($props, 'width');
                }
                @if map-has-key($props, 'color') 
                {
                    color: map-get($props, 'color');
                }
                @if map-has-key($props, 'line-height') 
                {
                  line-height: map-get($props, 'line-height');
                }
                @if map-has-key($props, 'max-width')
                {
                  max-width: map-get($props, 'max-width');;
                  @include sm {
                    max-width: unset;
                  }
                }
                @if map-has-key($props, 'min-width')
                {
                  min-width: map-get($props, 'min-width');
                  @include xs {
                    min-width: unset;
                  }
                }
            } 
        }
    }
}