/// Variables for both main and mobile
$font-family-sans-serif:  "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif:       Georgia, "Times New Roman", Times, serif;
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base:        $font-family-sans-serif;

$color-primary-text: white;

$color-bgmain:white;
$color-gold: #D7AE00;
$color-pink: #e059a1;

:root {
    --requiredValue: 102, 0, 17;
    --requiredColor: rgba(var(--requiredValue), 0.76);
    --redColor: 206, 0, 0;
    --warnColor: 225, 94, 0;
    --warnContrast: 255, 255, 255;
    --light-outline: rgba(0, 0, 0, .3);
    --outline: rgba(0, 0, 0, .5);
    --dark-outline: rgba(0, 0, 0, .7);
    --headerHeight: 46px;
    --mainContentHeightCompatibility: calc(100vh - var(--headerHeight));
    --mainContentHeight: calc(100dvh - var(--headerHeight));
}

$primary-palette: (
    00 : $color-bgmain,
    20 : #f7f7f7,
    50 : #e5e5e5,//table off/highlight
    100 : #cccccc,//input borders
    200 : #b5b3b3,//table borders
    300 : #999999,
    400 : #555,
    500 : #373737,//primary grey
    600 : #313131,
    700 : #2a2a2a,
    800 : #232323,
    900 : #161616,
    A000 : #e1f5ff,
    A100 : #c1e9fc,
    A200 : #A9CFDF,
    A400 : #77b9d5,
    A700 : #4fabd2,
    A800 : #004f71,
    A900 : #002a3c,
    contrast: (
        00 : #000000,
        20 : #000000,
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
        A800 : #ffffff,
        A900 : #ffffff,
    )
);
//#c1e9fc
$secondary-palette: (
    00 : $color-bgmain,
    20 : #f5f9f3,
    50 : #deebd9,
    100 : #cae1c1,
    200 : #a7ce97,
    300 : #83ba6d,
    400 : #69ab4e,
    500 : #4e9c2f,
    600 : #47942a,
    700 : #3d8a23,
    800 : #35801d,
    900 : #256e12,
    A100 : #b4ffa4,
    A200 : #89ff71,
    A400 : #5fff3e,
    A700 : #4aff25,
    contrast: (
        00 : #000000,
        20 : #000000,
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// $person-palette: (
//     none : map-get($primary-palette, 500),
//     0 : #80331a,
//     1 : #81232b,
//     2 : #792362,
//     3 : #742881,
//     4 : #64229e,
//     5 : #2f2881,
//     6 : #1f4a90,
//     7 : #216268,
//     8 : #1a6f49,
//     9 : #884c10,
// );
$person-palette: (
    none : map-get($primary-palette, 500),
    0 : #872f00,
    1 : #7b0039,
    2 : #570040,
    3 : #48005c,
    4 : #2f0071,
    5 : #130056,
    6 : #002c57,
    7 : #003243,
    8 : #003618,
    9 : #b97800,
    10 : #be4f00,
    11 : #b3003f,
    12 : #c80093,
    13 : #c800ff,
    14 : #5500cd,
    15 : #2b00c8,
    16 : #005cb9,
    17 : #0091c2,
    18 : #00a54a,
    19 : #7ea400,
    20 : #e15e00,
    21 : #ff0059,
    22 : #ce00bd,
    23 : #b700ff,
    24 : #6a00ff,
    25 : #3700ff,
    26 : #0080ff,
    27 : #00bfff,
    28 : #008037,
    29 : #ffb700,
);

:root {
    --temperatureRed: rgb(var(--redColor));
    --temperatureOrange: rgb(var(--warnColor));
    --temperatureYellow: #{$color-gold};
    --temperatureLime: #6cbc00;
    --temperatureGreen: #{map-get($secondary-palette, 700)};
    --temperatureTeal: #0c7775;
    --temperatureBlue: #{map-get($primary-palette, A700)};
    --temperatureBlack: #{mix(map-get($primary-palette, 900), map-get($primary-palette, A700), 80%)};
}

$shadeAmount: 20%;

$darkSpacer: rgba(map-get($primary-palette, 500), 10%);

$row-main: map-get($primary-palette, 00);
$row-off: map-get($primary-palette, 20);
$row-selected: map-get($primary-palette, A100);

$greenText: #076f07;
$greenButton: #38ba2f;
$redButton: #c84545;
$inactiveOrderYellow: #fff688;

$sidebar-width: 180px;
$sidebar-width-mobile: 100px;
$dashboard-height: 100px;

$small-gap: 4px;
$component-gap: 10px;
$widget-gap: 20px;

@function map-get-contrast($palette, $type, $hue) {
    @return map-get(map-get($palette, $type), contrast, $hue);
}

@mixin customScrollStyle {
    /* width */
    &::-webkit-scrollbar {
        width: 8px;
    }
  
    /* Track */
    &::-webkit-scrollbar-track {
        width: 8px;
        background: rgba(map-get($mr-theme, primary, 00), .4);
        &:hover{
            background: rgba(map-get($mr-theme, primary, 00), .8);
        }
    }
  
      /* Handle */
    &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        width: 8px;
        background: rgba(map-get($mr-theme, primary, 700), .2);
        &:hover{
            background: rgba(map-get($mr-theme, primary, 700), .4);
        }
    }
}

@mixin customVerticalScrollStyle {
    /* width */
    &::-webkit-scrollbar {
        width: $component-gap;
    }
    &::-webkit-scrollbar-button {
        display: none;
    }
  
    /* Track */
    &::-webkit-scrollbar-track {
        width: $component-gap;
        background: rgba(map-get($mr-theme, primary, 00), .4);
        &:hover{
            background: rgba(map-get($mr-theme, primary, 00), .8);
        }
    }
  
      /* Handle */
    &::-webkit-scrollbar-thumb {
        border-radius: $component-gap;
        width: $component-gap;
        background: rgba(map-get($mr-theme, primary, 700), .2);
        &:hover{
            background: rgba(map-get($mr-theme, primary, 700), .4);
        }
    }
}

@mixin customHorizontalScrollStyle {
    /* width */
    &::-webkit-scrollbar {
        height: $component-gap;
    }
    &::-webkit-scrollbar-button {
        display: none;
    }
  
    /* Track */
    &::-webkit-scrollbar-track {
        height: $component-gap;
        background: rgba(map-get($mr-theme, primary, 00), .4);
        &:hover{
            background: rgba(map-get($mr-theme, primary, 00), .8);
        }
    }
  
      /* Handle */
    &::-webkit-scrollbar-thumb {
        border-radius: $component-gap;
        height: $component-gap;
        background: rgba(map-get($mr-theme, primary, 700), .2);
        &:hover{
            background: rgba(map-get($mr-theme, primary, 700), .4);
        }
    }
}

$size-xs:460px;
$size-sm:600px;
$size-md:960px;
$size-lg:1280px;

@mixin xxs {
    @media (max-width: #{($size-xs - 1px)}) {
        @content;
    }
}

@mixin xs {
    @media (max-width: #{($size-sm - 1px)}) {
        @content;
    }
}
 
// Medium devices
@mixin sm {
    @media (max-width: #{($size-md - 1px)}) {
        @content;
    }
}
 
// Large devices
@mixin md {
    @media (max-width: #{($size-lg - 1px)}) {
        @content;
    }
}
 
@mixin gt-sm {
   @media (min-width: #{$size-md}) {
       @content;
   }
}
 
 @mixin gt-md {
    @media (min-width: #{$size-lg}) {
        @content;
    }
}

@mixin sm-visible {
    display:none;
    @include xs{
        display: inherit;
    }
}

@mixin md-visible {
    display:none;
    @include sm{
        display: inherit;
    }
}