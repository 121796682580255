
@function _get-umbra-map($color, $opacity) {
    $shadow-color: if(type-of($color) == color, rgba($color, $opacity * 0.2), $color);
  
    @return (
      0: '0px 0px 0px 0px #{$shadow-color}',
      1: '0px 2px 1px -1px #{$shadow-color}',
      2: '0px 3px 1px -2px #{$shadow-color}',
      3: '0px 3px 3px -2px #{$shadow-color}',
      4: '0px 2px 4px -1px #{$shadow-color}',
      5: '0px 3px 5px -1px #{$shadow-color}',
      6: '0px 3px 5px -1px #{$shadow-color}',
      7: '0px 4px 5px -2px #{$shadow-color}',
      8: '0px 5px 5px -3px #{$shadow-color}',
      9: '0px 5px 6px -3px #{$shadow-color}',
      10: '0px 6px 6px -3px #{$shadow-color}',
      11: '0px 6px 7px -4px #{$shadow-color}',
      12: '0px 7px 8px -4px #{$shadow-color}',
      13: '0px 7px 8px -4px #{$shadow-color}',
      14: '0px 7px 9px -4px #{$shadow-color}',
      15: '0px 8px 9px -5px #{$shadow-color}',
      16: '0px 8px 10px -5px #{$shadow-color}',
      17: '0px 8px 11px -5px #{$shadow-color}',
      18: '0px 9px 11px -5px #{$shadow-color}',
      19: '0px 9px 12px -6px #{$shadow-color}',
      20: '0px 10px 13px -6px #{$shadow-color}',
      21: '0px 10px 13px -6px #{$shadow-color}',
      22: '0px 10px 14px -6px #{$shadow-color}',
      23: '0px 11px 14px -7px #{$shadow-color}',
      24: '0px 11px 15px -7px #{$shadow-color}'
    );
  }
  
  @function _get-penumbra-map($color, $opacity) {
    $shadow-color: if(type-of($color) == color, rgba($color, $opacity * 0.14), $color);
  
    @return (
      0: '0px 0px 0px 0px #{$shadow-color}',
      1: '0px 1px 1px 0px #{$shadow-color}',
      2: '0px 2px 2px 0px #{$shadow-color}',
      3: '0px 3px 4px 0px #{$shadow-color}',
      4: '0px 4px 5px 0px #{$shadow-color}',
      5: '0px 5px 8px 0px #{$shadow-color}',
      6: '0px 6px 10px 0px #{$shadow-color}',
      7: '0px 7px 10px 1px #{$shadow-color}',
      8: '0px 8px 10px 1px #{$shadow-color}',
      9: '0px 9px 12px 1px #{$shadow-color}',
      10: '0px 10px 14px 1px #{$shadow-color}',
      11: '0px 11px 15px 1px #{$shadow-color}',
      12: '0px 12px 17px 2px #{$shadow-color}',
      13: '0px 13px 19px 2px #{$shadow-color}',
      14: '0px 14px 21px 2px #{$shadow-color}',
      15: '0px 15px 22px 2px #{$shadow-color}',
      16: '0px 16px 24px 2px #{$shadow-color}',
      17: '0px 17px 26px 2px #{$shadow-color}',
      18: '0px 18px 28px 2px #{$shadow-color}',
      19: '0px 19px 29px 2px #{$shadow-color}',
      20: '0px 20px 31px 3px #{$shadow-color}',
      21: '0px 21px 33px 3px #{$shadow-color}',
      22: '0px 22px 35px 3px #{$shadow-color}',
      23: '0px 23px 36px 3px #{$shadow-color}',
      24: '0px 24px 38px 3px #{$shadow-color}'
    );
  }
  
  @function _get-ambient-map($color, $opacity) {
    $shadow-color: if(type-of($color) == color, rgba($color, $opacity * 0.12), $color);
  
    @return (
      0: '0px 0px 0px 0px #{$shadow-color}',
      1: '0px 1px 3px 0px #{$shadow-color}',
      2: '0px 1px 5px 0px #{$shadow-color}',
      3: '0px 1px 8px 0px #{$shadow-color}',
      4: '0px 1px 10px 0px #{$shadow-color}',
      5: '0px 1px 14px 0px #{$shadow-color}',
      6: '0px 1px 18px 0px #{$shadow-color}',
      7: '0px 2px 16px 1px #{$shadow-color}',
      8: '0px 3px 14px 2px #{$shadow-color}',
      9: '0px 3px 16px 2px #{$shadow-color}',
      10: '0px 4px 18px 3px #{$shadow-color}',
      11: '0px 4px 20px 3px #{$shadow-color}',
      12: '0px 5px 22px 4px #{$shadow-color}',
      13: '0px 5px 24px 4px #{$shadow-color}',
      14: '0px 5px 26px 4px #{$shadow-color}',
      15: '0px 6px 28px 5px #{$shadow-color}',
      16: '0px 6px 30px 5px #{$shadow-color}',
      17: '0px 6px 32px 5px #{$shadow-color}',
      18: '0px 7px 34px 6px #{$shadow-color}',
      19: '0px 7px 36px 6px #{$shadow-color}',
      20: '0px 8px 38px 7px #{$shadow-color}',
      21: '0px 8px 40px 7px #{$shadow-color}',
      22: '0px 8px 42px 7px #{$shadow-color}',
      23: '0px 9px 44px 8px #{$shadow-color}',
      24: '0px 9px 46px 8px #{$shadow-color}'
    );
  }

// The default color for excavation shadows.
$mat-excavation-color: black !default;

// The default opacity scaling value for excavation shadows.
$mat-excavation-opacity: 1 !default;
// Applies the correct css rules to an element to give it the excavation specified by $zValue.
// The $zValue must be between 0 and 24.
@mixin mat-excavation($zValue, $color: $mat-excavation-color, $opacity: $mat-excavation-opacity, $gradColor:transparent) {
    @if type-of($zValue) != number or not unitless($zValue) {
      @error '$zValue must be a unitless number';
    }
    @if $zValue < 0 or $zValue > 24 {
      @error '$zValue must be between 0 and 24';
    }
    position:relative;
    &::before{
      content: '';
      position: absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      pointer-events: none;
      box-shadow: inset #{map-get(_get-umbra-map($color, $opacity), $zValue)},
                  inset #{map-get(_get-penumbra-map($color, $opacity), $zValue)},
                  inset #{map-get(_get-ambient-map($color, $opacity), $zValue)};
                  
      background: linear-gradient(0deg, $gradColor, transparent 0px), 
                  linear-gradient(90deg, $gradColor, transparent 16px),
                  linear-gradient(180deg, $gradColor, transparent 0px),
                  linear-gradient(270deg, $gradColor, transparent 16px);
    }
  }

@for $i from 0 through 24 {
    .mat-excavation-z#{$i}{
        @include mat-excavation($i, $gradColor:rgba(map-get($mr-theme, primary, A700), .2));
    }
}
@for $i from 0 through 24 {
    .mat-excavation-accent-z#{$i}{
        @include mat-excavation($i, $gradColor:rgba(map-get($mr-theme, accent, 500), .2));
    }
}
@for $i from 0 through 24 {
    .mat-excavation-dark-z#{$i}{
        @include mat-excavation($i, $gradColor:rgba(map-get($mr-theme, primary, 900), .2));
    }
}
@for $i from 0 through 24 {
    .mat-excavation-less-z#{$i}{
        @include mat-excavation($i, $gradColor:rgba(map-get($mr-theme, primary, 900), .1));
    }
}